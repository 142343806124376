/* src/App.css */
.App {
  font-family: Arial, sans-serif;
  text-align: center;
  background-image: url('/public/images/bg.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  overflow: hidden; /* Prevent scrolling */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px; /* Add padding to push content from the top */
  box-sizing: border-box; /* Ensure padding is included in the height */
}
