/* src/components/MainSection.css */
.main-section {
  display: grid;
  grid-template-columns: repeat(12, 1fr); /* Create 12 equal columns */
  gap: 10px; /* Adjust gap as needed */
  padding: 20px;
  width: 100%; /* Ensure it takes up full width */
  height: 100vh; /* Full viewport height */
  overflow: hidden; /* Prevent scrolling */
  position: relative; /* Needed for absolute positioning of footer */
  align-items: start; /* Align items to the start to push content to the top */
}

.left-section {
  grid-column: 2 / 6; /* Occupies columns 2 to 5 */
  display: flex;
  justify-content: center;
  align-items: start; /* Align items to the start */
  animation: slideInLeft 1s ease-in-out; /* Add slide-in animation */
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.left-image {
  max-width: 100%;
  height: auto;
  max-height: 600px; /* Adjust this value as needed */
}

.right-section {
  grid-column: 6 / 11; /* Occupies columns 6 to 10 */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main-image, .additional-image {
  max-width: 100%;
  height: auto;
  max-height: 300px; /* Adjust this value as needed */
  margin-bottom: 10px; /* Add some space between images */
}

.links-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: url('/public/images/frame.png') no-repeat center center; /* Use frame.png as background */
  background-size: 130% 130%; /* Increase the background size by 30% */
  border-radius: 10px;
  width: 100%; /* Ensure it spans the full width of its container */
}

.links-columns {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.links-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.link-image {
  margin: 5px;
  cursor: pointer;
  width: 300px; /* Adjust the width to fit your design */
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.buttons img {
  margin: 0 10px;
  cursor: pointer;
  width: 190px; /* Adjust the width to make the buttons smaller */
  height: auto;
}

.footer-text {
  color: gray;
  font-size: small;
  text-align: center;
  position: absolute;
  bottom: 10px; /* Adjust if necessary to get the desired spacing from bottom */
  width: 100%;
}
